//对外暴露配置路由(常量路由):全部用户都可以访问到的路由
export const constantRoute = [
  {
    path: "/",
    redirect: "/login",
    meta: {
      title: null,
      hidden: true,
      icon: null
    }
  },
  {
    //登录
    path: "/login",
    component: () => import("@/views/login/index.vue"),
    name: "login",
    meta: {
      title: "登录", //菜单标题
      hidden: true, //代表路由标题在菜单中是否隐藏  true:隐藏 false:不隐藏
      icon: "Promotion" //菜单文字左侧的图标,支持element-plus全部图标
    }
  },

  {
    // 注册
    path: "/register",
    component: () => import("@/views/register/index.vue"),
    name: "register",
    meta: {
      title: "注册", //菜单标题
      hidden: true, //代表路由标题在菜单中是否隐藏  true:隐藏 false:不隐藏
      icon: "Promotion" //菜单文字左侧的图标,支持element-plus全部图标
    }
  },

  {
    //登录成功以后展示数据的路由
    path: "/index",
    component: () => import("@/layout/index.vue"),
    name: "Layout",
    meta: {
      title: "公共导航",
      hidden: false,
      icon: "ChromeFilled"
    },
    redirect: "/welcome",
    children: [
      {
        //欢迎页面
        path: "/welcome",
        component: () => import("@/views/welcome/index.vue"),
        name: "welcome",
        meta: {
          title: "欢迎", //菜单标题
          hidden: false, //代表路由标题在菜单中是否隐藏  true:隐藏 false:不隐藏
          icon: "Promotion" //菜单文字左侧的图标,支持element-plus全部图标
        }
      },
      {
        path: "/home",
        component: () => import("@/views/home/index.vue"),
        meta: {
          title: "接口大厅",
          hidden: false,
          icon: "HomeFilled"
        }
      }
    ]
  },
  {
    path: "/interface",
    component: () => import("@/layout/index.vue"),
    name: "interface",
    meta: {
      hidden: true
    },
    children: [
      {
        path: "/interface_info/:id",
        name: "interface",
        meta: {
          title: "接口信息",
          hidden: true,
          icon: "Management"
        },
        component: () => import("@/views/home/user/interface/index.vue")
      }
    ]
  },
  {
    //404
    path: "/404",
    component: () => import("@/views/404/index.vue"),
    name: "404",
    meta: {
      title: "404",
      hidden: true,
      icon: "DocumentDelete"
    }
  }
];

//异步路由(管理员)
export const asnycAdminRoute = [
  {
    path: "/admin",
    component: () => import("@/layout/index.vue"),
    name: "admin",
    meta: {
      title: "管理页",
      icon: "Tools"
    },
    redirect: "/admin/manage",
    children: [
      {
        path: "/admin/manage",
        component: () => import("@/views/home/admin/manage/index.vue"),
        name: "manage",
        meta: {
          title: "接口管理",
          icon: "Management"
        }
      },
      {
        path: "/admin/analyse",
        component: () => import("@/views/home/admin/analyse/index.vue"),
        name: "analyse",
        meta: {
          title: "接口分析",
          icon: "DataAnalysis"
        }
      },
      {
        path: "/interface_info/:id",
        name: "interface",
        meta: {
          title: "接口信息",
          hidden: true,
          icon: "Management"
        },
        component: () => import("@/views/home/user/interface/index.vue")
      },
      {
        path: "/admin/account",
        component: () => import("@/views/home/account/index.vue"),
        name: "account",
        meta: {
          title: "个人主页",
          icon: "UserFilled",
          hidden: true
        }
      },
      {
        path: "/interface/statistics",
        name: "interfaceStatistic",
        meta: {
          title: "接口统计",
          hidden: false,
          icon: "PieChart"
        },
        component: () => import("@/views/home/admin/statistics/index.vue")
      }
    ]
  }
];
//异步路由(用户)
export const asnycUserRoute = [
  {
    path: "/user",
    component: () => import("@/layout/index.vue"),
    name: "user",
    meta: {
      title: "接口大厅",
      icon: "Tools"
    },
    redirect: "/home",
    children: [
      {
        path: "/user/account",
        component: () => import("@/views/home/account/index.vue"),
        name: "account",
        meta: {
          title: "个人主页",
          icon: "UserFilled",
          hidden: true
        }
      }
    ]
  }
];
//任意路由
export const anyRoute = {
  //任意路由
  path: "/:pathMatch(.*)*",
  redirect: "/404",
  name: "Any",
  meta: {
    title: "任意路由",
    hidden: true,
    icon: "DataLine"
  }
};
