// main.ts
import { createApp } from "vue";
import ElementPlus from "element-plus";
import zhCn from "element-plus/es/locale/lang/zh-cn";
import "element-plus/dist/index.css";
import App from "./App.vue";
// 引入全局样式
import "@/styles/index.scss";
// 引入路由
import router from "./router";
// 引入仓库
import pinia from "./store";
// 引入路由守卫
import "./permission.ts";
// 导入暗黑模式需要的样式
import "element-plus/theme-chalk/dark/css-vars.css";
// 引入自定义插件对象:注册整个项目全局组件
import gloablComponent from "@/components/index";
// 导入fonts阿里巴巴字体库
import "@/assets/fonts/iconfont.css";

const app = createApp(App);
import useUserStore from "@/store/modules/user";

const userStore = useUserStore();
userStore.userInfo();
// 使用ElementUIPlus组件库
app.use(ElementPlus, {
  locale: zhCn
});
// 注册全局组件
app.use(gloablComponent);
// 使用路由
app.use(router);
// 安装仓库
app.use(pinia);

app.mount("#app");
